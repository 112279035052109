import React from "react"

import { Link } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Memoji from "../images/davememoji.png"

import "../styles/main.scss"

const ThanksPage = () => {
  return (
    <Layout>
      <SEO title="Thanks" />
      <div className="container full-height">
        <h1 className="main-title">Thanks for your submission!</h1>
        <div>
          <img className="memoji" src={Memoji} alt="Dave Memoji" />
        </div>
        <div className="text-center">
          Go <Link to="/">back</Link> to home page
        </div>
      </div>
    </Layout>
  )
}

export default ThanksPage
